import React, { useState } from 'react';
import SmoothCollapse from 'react-smooth-collapse';

const Accordion = ({title, content}) => {
    const [isActive, setIsActive] = useState(false);
    return (
        <div className={`accordion__item ${isActive ? 'active' : ''}`}>
            <div className="accordion_title" onClick={() => setIsActive(!isActive)}>
                <h3>{title}</h3>
            </div>
            <SmoothCollapse expanded={isActive} heightTransition=".4s ease">
            {/* <div className={`accordion__content ${isActive ? 'active' : ''}`}> */}
                <div className="accordion__content active">
                    <p>{content}</p>
                </div>
            </SmoothCollapse>
        </div>
    );
};

export default Accordion;
