import React from 'react';
import './index.css';
import discord from '../../assets/images/discord.png';
import footer1 from '../../assets/images/Group 38700.png';
import footer2 from '../../assets/images/Group 38698.png';
import instagram2 from '../../assets/images/instagram2.png';
import tiktok from '../../assets/images/tiktok.png';
import footer3 from '../../assets/images/Group 38699.png';
import footer4 from '../../assets/images/GP-logo-clearbg.svg';
import footer5 from '../../assets/images/footer-gamepad.png';
import { withTranslation } from 'react-i18next';

const Footer = ({ t }) => {
    
    const year = new Date().getFullYear();
    
    return (
        <section className="footer">
            <div className="footer__wrapper">
                <div className="footer__container">
                    <div className="footer__title">
                        <h3>{t('footer.title')}</h3>
                    </div>
                    <div className="footer__sub-title">
                        <span>{t('footer.content')}</span>
                    </div>
                    <div className="footer__socials">
                        <ul>
                            <li><a href="https://discord.gg/gamepad"><img src={discord} alt="" /></a></li>
                            <li><a href="https://twitter.com/gamepadco"><img src={footer1} alt="" /></a></li>
                            <li><a href="https://blog.gamepad.co/"><img src={footer2} alt="" /></a></li>
                            <li><a href="https://www.instagram.com/gamepadco"><img src={instagram2} alt="" /></a></li>
                            <li><a href="https://www.tiktok.com/@gamepadco"><img src={tiktok} alt="" /></a></li>
                            <li><a href="https://t.me/gamepadco"><img src={footer3} alt="" /></a></li>
                        </ul>
                    </div>
                </div>
                <div className="footer__bottom">
                    <div className="footer__logo">
                        <img src={footer4} alt="" />
                        <span>Gamepad.co</span>
                    </div>

                    <div className="footer__copyright">
                        <span>{t('footer.copyright', { year })}</span>
                    </div>
                </div>
                <div className="footer__image">
                    <img src={footer5} alt=""/>
                </div>
            </div>
        </section>
    );
};

export default withTranslation(['translation'])(Footer);
