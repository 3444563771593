import React, { useRef, useState } from 'react';
import PhotoContent from './PhotoContent';
import './index.css';
import Banner from './Banner';
import Faq from './Faq';
import Introduction from './Introduction';
import Investors from './Investors';
import Supporters from './Supporters';
import Partners from './Partners';
import Team from './Team';
import Roadmap from './Roadmap';
import Faq2 from './Faq2';
import Popup from '../../components/Popup';
import Features from './Features';

const LandingPage = ({ feature, team }) => {
    const [popup, setPopup] = useState(false);
    const roadmapRef = useRef(null);
    const investorsRef = useRef(null);
  
    const executeRoadmap = () => roadmapRef.current.scrollIntoView();
    const executeInvestors = () => investorsRef.current.scrollIntoView();

    const handlePopup = () => {
        setPopup(!popup);
    }

  return (
        <>
            <Banner handlePopup={handlePopup}/>
            <Features featureRef={feature}/>
            <PhotoContent executeInvestors={executeInvestors} executeRoadmap={executeRoadmap} handlePopup={handlePopup}/>
            <Faq />
            <Introduction />
            <Investors investorsRef={investorsRef}/>
            <Supporters />
            <Partners />
            <Team teamRef={team}/>
            <Roadmap roadmapRef={roadmapRef} />
            <Faq2 />
            <Popup handlePopup={handlePopup} popup={popup}/>
        </>
    );
};

export default LandingPage;