import React from 'react';
import { withTranslation } from 'react-i18next';
import introduction1 from '../../assets/images/coingpad 1.png';

const Introduction = ({ t }) => {
    return (
        <section className="introduction">
            <div className="introduction__wrapper">
                <div className="introduction__top-content">
                    <div className="introduction__title">{t('landing_page.introduction.title')}</div>
                </div>
                <div className="introduction__bottom-content">
                    <div className="introduction__image">
                        <img src={introduction1} alt={t('landing_page.introduction.title')} />
                         <div className="blur-circle dark-blue"></div>
                    </div>
                    <div className="introduction__list">
                        <ul>
                            <li>{t('landing_page.introduction.list_1')}</li>
                            <li>{t('landing_page.introduction.list_2')}</li>
                            <li>{t('landing_page.introduction.list_3')}</li>
                            <li>{t('landing_page.introduction.list_4')}</li>
                            <li>{t('landing_page.introduction.list_5')}</li>
                            <li>{t('landing_page.introduction.list_6')}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default withTranslation(['translation'])(Introduction);
