import React from 'react';
import { withTranslation } from 'react-i18next';
import Accordion from '../../components/Accordion';

const Faq2 = ({ t }) => {
    const data = [
        {
            title: t('landing_page.faq2.accordion_1.title'),
            content: t('landing_page.faq2.accordion_1.content')
        }, {
            title: t('landing_page.faq2.accordion_2.title'),
            content: t('landing_page.faq2.accordion_2.content')
        }, {
            title: t('landing_page.faq2.accordion_3.title'),
            content: t('landing_page.faq2.accordion_3.content')
        }, {
            title: t('landing_page.faq2.accordion_4.title'),
            content: t('landing_page.faq2.accordion_4.content')
        }, {
            title: t('landing_page.faq2.accordion_5.title'),
            content: t('landing_page.faq2.accordion_5.content')
        }
    ]
    return (
        <section className="faq2">
            <div className="faq2__wrapper">
                <div className="faq2__title">
                    <h2>{ t('landing_page.faq2.title')}</h2>
                </div>
                <div className="separator"></div>
                <div className="faq2__accordion">
                    {data.map(({title, content}) => (
                        <Accordion key={title} title={title} content={content} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default withTranslation(['translation'])(Faq2);
