import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import enjinImg from '../../assets/images/Group 39263.png';
import okexImg from '../../assets/images/okexblockdream 1.png';
import meritCircle from '../../assets/images/p2.png';
import near from '../../assets/images/near.png';

const Investors = ({t, investorsRef}) => {
    return (
        <>
            <div id="investors" ref={investorsRef}></div>
            <section className="investors" >
                <div className="investors__wrapper">
                    <div className="investors__title">
                        <h2>{t('landing_page.investors.title')}</h2>
                    </div>
                    <div className="separator"></div>
                    {/* <span className="investors__text"><Trans>{t('landing_page.investors.content')}</Trans></span> */}
                    <div className="investors__images">
                        <a href='https://enjin.io/'>
                            <img src={enjinImg} alt="Enjin" />
                        </a>
                        <a href='https://www.okx.com/blockdream-ventures'>
                            <img src={okexImg} alt="Enjin" />
                        </a>
                        <a href='https://blog.gamepad.co/merit-circle-partners-with-gamepad/'>
                            <img src={meritCircle} alt="Merit Circle" />
                        </a>
                        <a href='https://blog.gamepad.co/near-foundation-awards-development-grant-to-gamepad/'>
                            <img src={near} alt="Merit Circle" />
                        </a>
                    </div>
                </div>
            </section>
        </>
    );
};

export default withTranslation(['translation'])(Investors);
