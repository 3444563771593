import React from 'react';
import { withTranslation } from 'react-i18next';
import twitter from '../../assets/images/twitter.png';
import instagram from '../../assets/images/instagram.png';
import g1 from '../../assets/images/Group 39260.png';
import g2 from '../../assets/images/Group 39261.png';
import g3 from '../../assets/images/Group 39262.png';

const Team = ({t, teamRef}) => {
    return (
        <>
            <div id="team" ref={teamRef}></div>
            <section className="team" >
                <div className="team__wrapper">
                    <div className="blur-circle light-blue"></div>
                    <div className="team__title">
                        <h2>{t('landing_page.team.title')}</h2>
                    </div>
                    <div className="separator"></div>
                    <div className="team__list">
                        <div className="team__item">
                            <div className="team__image">
                                <img src={g1} alt="" />
                            </div>
                            <div className="team__name">
                                <span>{t('landing_page.team.list_1.name')}</span>
                            </div>
                            <div className="team__description">
                                <p>{t('landing_page.team.list_1.content')}</p>
                            </div>
                            <div className="team__socials">
                                <a className="team__twitter" href="https://twitter.com/ericzoo"><img src={twitter} alt="twitter" /></a>
                                <a className="team__ig" href="https://www.linkedin.com/in/ericsu/"><img src={instagram} alt="instragram" /></a>
                            </div>
                        </div>
                        <div className="team__item">
                            <div className="team__image">
                                <img src={g2} alt="" />
                            </div>
                            <div className="team__name">
                                <span>{t('landing_page.team.list_2.name')}</span>
                            </div>
                            <div className="team__description">
                                <p>{t('landing_page.team.list_2.content')}</p>
                            </div>
                            <div className="team__socials">
                                <a className="team__twitter" href="https://twitter.com/AbhishekRathod"><img src={twitter} alt="twitter" /></a>
                                <a className="team__ig" href="https://www.linkedin.com/in/abhi147/"><img src={instagram} alt="instragram" /></a>
                            </div>
                        </div>
                        <div className="team__item">
                            <div className="team__image">
                                <img src={g3} alt="" />
                            </div>
                            <div className="team__name">
                                <span>{t('landing_page.team.list_3.name')}</span>
                            </div>
                            <div className="team__description">
                                <p>{t('landing_page.team.list_3.content')}</p>
                            </div>
                            <div className="team__socials">
                                <a className="team__twitter" href="https://twitter.com/bebekdavor"><img src={twitter} alt="twitter" /></a>
                                <a className="team__ig" href="https://www.linkedin.com/in/davorin-bebek-46466813a/"><img src={instagram} alt="instragram" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="team__text">
                        <h3>{t('landing_page.team.text_title')}</h3>
                        <p>{t('landing_page.team.text_content')}</p>
                    </div>
                    <div className="team__cta">
                        <a className="btn--solid" id="ambassador-link" target="_blank" href="https://forms.gle/z9Yv9gMo3rdLxkBR7" rel="noreferrer">
                            <span>{t('landing_page.team.button_top_text')}</span>
                            <span>{t('landing_page.team.button_bottom_text')}</span>
                        </a>
                    </div>
                </div>
            </section>
        </>
    );
};

export default withTranslation(['translation'])(Team);
