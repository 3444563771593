import React from 'react';
import play from '../../assets/images/play.png';
import hero1 from '../../assets/images/hero-left-img.png';
import hero2 from '../../assets/images/hero-right-img.png';
import guide from '../../assets/images/555x294.png';
import { Trans, withTranslation } from 'react-i18next';

const Banner = ({t, handlePopup}) => {
    return (
        <section className="header">
            <div className="header__wrapper">
                <div className="header__top-content">
                    <div className="header__title">
                        <span className="before-span"><Trans>{t('landing_page.banner.title')}</Trans></span>
                        <span className="before-span-2"><Trans>{t('landing_page.banner.title')}</Trans></span>
                        <span className="before-span-3"><Trans>{t('landing_page.banner.title')}</Trans></span>
                        <h1><Trans>{t('landing_page.banner.title')}</Trans></h1>
                        <span className="before-span"><Trans>{t('landing_page.banner.title')}</Trans></span>
                        <span className="after-span-2"><Trans>{t('landing_page.banner.title')}</Trans></span>
                        <span className="after-span-3"><Trans>{t('landing_page.banner.title')}</Trans></span>
                    </div>
                    <div className="header__sub">
                        <span></span>
                    </div>
                    <div className="header__video" onClick={handlePopup}>
                        <img src={guide} alt="" className="guide" />
                        <img src={play} alt="" />
                    </div>
                    <div className="header__links">
                        <button className="btn--solid coming-soon" onClick={handlePopup}>{t('landing_page.banner.btn_1')}</button>
                        <a className="btn--outlined" href="https://discord.gg/gamepad">{t('landing_page.banner.btn_2')}</a>
                    </div>
                </div>
                
                <img className="header__image-1" src={hero1} alt="" />
                <img className="header__image-2" src={hero2} alt=""/>
            </div>
        </section>
    )
};

export default withTranslation(['translation'])(Banner);
