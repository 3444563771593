import React from 'react';
import './index.css';
import logo from '../../assets/images/GP-logo-clearbg.svg';
import discord from '../../assets/images/discord-large.png';
import twitter from '../../assets/images/twitter-large.png';
import { Link } from 'react-router-dom';

const Popup = ({handlePopup, popup}) => {
    return (
        <div className={`popup ${popup ? 'active' : ''}`}>
            <div className="popup__click" onClick={handlePopup}></div>
            <div className="popup__wrapper" id="modalBox">
                <div className="popup__logo" onClick={handlePopup}>
                    <img src={logo} alt="" />
                </div>
                <div className="popup__title">
                    <span>You early.</span>
                    <span>This part is coming soon!</span>
                    <p>Follow us on Discord or Twitter for updates.</p>
                </div>
                <div className="popup__socials">
                    <ul>
                        <li><Link to="https://discord.gg/gamepad"><img src={discord} alt="" /></Link></li>
                        <li><Link to="https://twitter.com/gamepadco"><img src={twitter} alt=""/></Link></li>
                    </ul>
                </div>
            </div>
        </div>
    )
};

export default Popup;
