import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import sup1 from '../../assets/images/Polygon 1.svg';

const Supporters = ({t}) => {
    return (
        <section className="supporters">
        <div className="supporters__wrapper">
            <img className="supporters__circle spinning" src={sup1} alt="" />
            <div className="supporters__title">
                <h2>{t('landing_page.supporters.title')}</h2>
            </div>
            <div className="separator"></div>
            <div className="supporters__text"><Trans>{t('landing_page.supporters.content')}</Trans></div>
        </div>
    </section>
    );
};

export default withTranslation(['translation'])(Supporters);
