import React from 'react';
import { withTranslation } from 'react-i18next';

const Roadmap = ({t, roadmapRef}) => {
    return (
        <>
            <div id="roadmap" ref={roadmapRef}></div>
            <section className="roadmap">
                <div className="roadmap__wrapper">
                    <div className="roadmap__title">
                        <h2>{t('landing_page.roadmap.title')}</h2>
                    </div>
                    <div className="separator"></div>
                    <div className="roadmap__list">
                        <div className="roadmap__item">
                            <div className="roadmap__year">
                                <span>{t('landing_page.roadmap.list_1.year')}</span>
                            </div>
                            <div className="roadmap__month">
                                <span>{t('landing_page.roadmap.list_1.month')}</span>
                            </div>
                            <div className="roadmap__description">
                                <ul>
                                    <li>{t('landing_page.roadmap.list_1.li_1')}</li>
                                    <li>{t('landing_page.roadmap.list_1.li_2')}</li>
                                    <li>{t('landing_page.roadmap.list_1.li_3')}</li>
                                    <li>{t('landing_page.roadmap.list_1.li_4')}</li>
                                    <li>{t('landing_page.roadmap.list_1.li_5')}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="roadmap__item">
                            <div className="roadmap__year">
                                <span>{t('landing_page.roadmap.list_2.year')}</span>
                            </div>
                            <div className="roadmap__month">
                                <span>{t('landing_page.roadmap.list_2.month')}</span>
                            </div>
                            <div className="roadmap__description">
                                <ul>
                                    <li>{t('landing_page.roadmap.list_2.li_1')}</li>
                                    <li>{t('landing_page.roadmap.list_2.li_2')}</li>
                                    <li>{t('landing_page.roadmap.list_2.li_3')}</li>
                                    <li>{t('landing_page.roadmap.list_2.li_4')}</li>
                                    <li>{t('landing_page.roadmap.list_2.li_5')}</li>
                                </ul>
                            </div>
                        </div>
                        <div className="roadmap__item">
                            <div className="roadmap__year">
                                <span>{t('landing_page.roadmap.list_3.year')}</span>
                            </div>
                            <div className="roadmap__month">
                                <span>{t('landing_page.roadmap.list_3.month')}</span>
                            </div>
                            <div className="roadmap__description">
                                <ul>
                                    <li>{t('landing_page.roadmap.list_3.li_1')}</li>
                                    <li>{t('landing_page.roadmap.list_3.li_2')}</li>
                                    <li>{t('landing_page.roadmap.list_3.li_3')}</li>
                                    <li>{t('landing_page.roadmap.list_3.li_4')}</li>
                                    <li>{t('landing_page.roadmap.list_3.li_5')}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="roadmap__text">
                        <h3>{t('landing_page.roadmap.text_title')}</h3>
                        <p>{t('landing_page.roadmap.text_content')}</p>
                    </div>
                </div>
            </section>
        </>
    );
};

export default withTranslation(['translation'])(Roadmap);
