import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SmoothCollapse from 'react-smooth-collapse';
import './index.css';
import logo from '../../assets/images/GP-logo-clearbg.svg';
import { withTranslation } from 'react-i18next';

const Navigation = ({t, executeFeature, executeTeam, languages, selectedLanguage, handleLanguageChange}) => {
    const [scroll, setScroll] = useState(false);
    const [hamburger, setHamburger] = useState(false);
    const [ select, setSelect] = useState({});
    const [openLanguage, setOpenLanguage] = useState(false);

    useEffect(() => {
        setSelect(languages.find((x) => x.code === selectedLanguage));
    }, [languages, selectedLanguage]);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            setScroll(window.scrollY > 50);
        });
    }, []);

    const handleHamburger = () => {
        setHamburger(!hamburger);
    };

    const handleLanguage = (code) => {
        setOpenLanguage(false);
        handleLanguageChange(code);
    }
    

    return (
        <nav className="navigation">
            <div className={`header__nav ${scroll ? 'active' : ''}`}>
                <div className="header__logo">
                    <img alt="" src={logo} />
                    <span>Gamepad.co</span>
                </div>
                <div className="header__hamburger">
                    <div className={`hamburger ${hamburger ? 'active' : ''}`} onClick={handleHamburger}>
                        <span></span>
                    </div>
                </div>
                <div className={`header__menu ${hamburger ? 'active' : ''}`}>
                    <div className="header__menubox">
                        <ul>
                            <li><Link to="#">{t('nav.home')}</Link></li>
                            <li><Link to="#features" onClick={executeFeature}>{t('nav.feature')}</Link></li>
                            <li><Link to="#team" onClick={executeTeam}>{t('nav.team')}</Link></li>
                            <li><a href='https://blog.gamepad.co/'>{t('nav.blog')}</a></li>
                            <li><a className="btn--solid" href="https://www.dropbox.com/s/21n1wp34r52dqf6/Gamepad.co%20deck_compressed.pdf?dl=0">{t('nav.game_paper')}</a></li>
                        </ul>
                        <div className="language language--desktop">
                            <div className="language-select" onClick={() => setOpenLanguage(!openLanguage)}>
                                <div>
                                    {select && select.flag}
                                </div>
                            </div>
                            
                            <SmoothCollapse expanded={openLanguage} heightTransition=".4s ease">
                                <div
                                    className="tw-cursor-pointer tw-text-white tw-bg-transparent"
                                    value={selectedLanguage}
                                    onChange={handleLanguageChange}
                                >
                                    {languages.map((_lang, i) => (
                                        <div key={i} className="language__item tw-text-black flex flex-row w-full" onClick={() => handleLanguage(_lang.code)}>
                                            {_lang.flag}
                                            <span>{_lang.label}</span>
                                        </div>
                                    ))}
                                </div>
                            </SmoothCollapse> 
                        </div>
                        <div className="language language--mobile">
                            <div className="language-select" onClick={() => setOpenLanguage(!openLanguage)}>
                                <div>
                                    {select && select.flag}
                                </div>
                            </div>
                            
                            <SmoothCollapse expanded={openLanguage} heightTransition=".4s ease">
                                <div
                                    className="tw-cursor-pointer tw-text-white tw-bg-transparent"
                                    value={selectedLanguage}
                                    onChange={handleLanguageChange}
                                >
                                    {languages.map((_lang, i) => (
                                        <div key={i} className="language__item tw-text-black flex flex-row w-full" onClick={() => handleLanguage(_lang.code)}>
                                            {_lang.flag}
                                            <span>{_lang.label}</span>
                                        </div>
                                    ))}
                                </div>
                            </SmoothCollapse> 
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default withTranslation()(Navigation);
