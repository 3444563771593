import React from 'react';
import { Trans, withTranslation } from 'react-i18next';

const Partners = ({ t }) => {
    return (
        <section className="partners">
            <div className="partners__wrapper">
                <div className="partners__title">
                        <h2>{t('landing_page.partners.title')}</h2>
                </div>
                <div className="separator"></div>
                <div className="partners__text"><Trans>{t('landing_page.partners.content')}</Trans></div>
                <div className="blur-circle sky-blue bounce-sky-blue"></div>
                <div className="blur-circle pink"></div>
            </div>
        </section>
    );
};

export default withTranslation(['translation'])(Partners);
