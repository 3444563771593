import React from "react";
import { withTranslation } from 'react-i18next';
import icon1 from '../../assets/images/incubation.png';
import icon2 from '../../assets/images/rocket.png';
import icon3 from '../../assets/images/staking.png';
import icon4 from '../../assets/images/genispad.png';
import icon5 from '../../assets/images/rewards.png';
import icon6 from '../../assets/images/council.png';
import icon7 from '../../assets/images/guild.png';
import icon8 from '../../assets/images/marketing.png';
import icon9 from '../../assets/images/analytics.png';
import icon10 from '../../assets/images/multichain.png';

const Features = ({t, featureRef}) => {
    return (
        <>
            <div id="features" ref={featureRef}></div>
            <section className="features">
                <div className="features__wrapper">
                    <div className="header__bottom-content">
                        <span>{t('landing_page.features.title')}</span>
                        <div className="separator"></div>
                        <p>{t('landing_page.features.content')}</p>
                    </div>
                    <div className="header__icon-list">
                        <div className="icon-list__row">
                            <div className="icon-list__item">
                                <div className="icon-list__imgbox">
                                    <img src={icon1} alt={t('landing_page.features.icon_1')} />
                                </div>
                                <span>{t('landing_page.features.icon_1')}</span>
                            </div>
                            <div className="icon-list__item">
                                <div className="icon-list__imgbox">
                                    <img src={icon2} alt={t('landing_page.features.icon_2')} />
                                </div>
                                <span>{t('landing_page.features.icon_2')}</span>
                            </div>
                            <div className="icon-list__item">
                                <div className="icon-list__imgbox">
                                    <img src={icon3} alt={t('landing_page.features.icon_3')} />
                                </div>
                                <span>{t('landing_page.features.icon_3')}</span>
                            </div>
                            <div className="icon-list__item">
                                <div className="icon-list__imgbox">
                                    <img src={icon4} alt={t('landing_page.features.icon_4')} />
                                </div>
                                <span>{t('landing_page.features.icon_4')}</span>
                            </div>
                            <div className="icon-list__item">
                                <div className="icon-list__imgbox">
                                    <img src={icon5} alt={t('landing_page.features.icon_5')} />
                                </div>
                                <span>{t('landing_page.features.icon_5')}</span>
                            </div>
                            <div className="icon-list__item">
                                <div className="icon-list__imgbox">
                                    <img src={icon6} alt={t('landing_page.features.icon_6')} />
                                </div>
                                <span>{t('landing_page.features.icon_6')}</span>
                            </div>
                            <div className="icon-list__item">
                                <div className="icon-list__imgbox">
                                    <img src={icon7} alt={t('landing_page.features.icon_7')} />
                                </div>
                                <span>{t('landing_page.features.icon_7')}</span>
                            </div>
                            <div className="icon-list__item">
                                <div className="icon-list__imgbox">
                                    <img src={icon8} alt={t('landing_page.features.icon_8')} />
                                </div>
                                <span>{t('landing_page.features.icon_8')}</span>
                            </div>
                            <div className="icon-list__item">
                                <div className="icon-list__imgbox">
                                    <img src={icon9} alt={t('landing_page.features.icon_9')} />
                                </div>
                                <span>{t('landing_page.features.icon_9')}</span>
                            </div>
                            <div className="icon-list__item">
                                <div className="icon-list__imgbox">
                                    <img src={icon10} alt={t('landing_page.features.icon_10')} />
                                </div>
                                <span>{t('landing_page.features.icon_10')}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
};

export default withTranslation(['translation'])(Features);
