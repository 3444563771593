import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import faq1 from '../../assets/images/OGfront 1.png';
import faq2 from '../../assets/images/OGback 1.png';
import faq3 from '../../assets/images/Polygon 1.svg';
import faq4 from '../../assets/images/Union.png';

const Faq = ({ t }) => {
    return (
        <section className="faq">
            <div className="faq__wrapper">
                <div className="faq__top-content">
                    <div className="faq__title"><Trans>{t('landing_page.faq.title')}</Trans></div>
                    <div className="separator"></div>
                </div>
                <div className="faq__bottom-content">
                    <div className="faq__image">
                        <img className="controller controller--front bobbing" src={faq1} alt="" />
                        <img className="controller controller--back bobbing" src={faq2} alt="" />
                        <img className="triangle-img spinning" src={faq3} alt="" />
                        <img className="x-img spinning" src={faq4} alt="" />
                        <div className="blur-circle sky-blue bounce-sky-blue"></div>
                    </div>
                    <div className="faq__list">
                        <ul>
                            <li>{t('landing_page.faq.list_1')}</li>
                            <li>{t('landing_page.faq.list_2')}</li>
                            <li>{t('landing_page.faq.list_3')}</li>
                            <li>{t('landing_page.faq.list_4')}</li>
                            <li>{t('landing_page.faq.list_5')}</li>
                            <li>{t('landing_page.faq.list_6')}</li>
                        </ul>
                    </div>
                    <div className="faq__button">
                        <a className="btn--solid" href="https://ericzoo.typeform.com/to/XIzg98sq">{t('landing_page.faq.button')}</a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default withTranslation(['translation'])(Faq);
