import React, { useRef, useEffect, useCallback, useState } from 'react';
import { Route, withRouter } from 'react-router-dom';
import Footer from './components/Footer';
import Navigation from './components/Navigation';
import LandingPage from './containers/LandingPage';
import { withTranslation } from 'react-i18next';
import Flags from 'country-flag-icons/react/3x2';

function App({ i18n }) {
  const featureRef = useRef(null);
  const teamRef = useRef(null);
  const languages = [
    {
      code: 'zh',
      label: 'Chinese',
      flag: <Flags.CN title="China" className="..."/>,
    },
    {
      code: 'nl',
      label: 'Dutch',
      flag: <Flags.NL title="DUTCH" className="..."/>,
    },
    {
      code: 'en',
      label: 'English',
      flag: <Flags.US title="United States" className="..."/>,
    },
    {
      code: 'ko',
      label: 'Korean',
      flag: <Flags.KR title="Korea" className="..."/>,
    },
    {
      code: 'ru',
      label: 'Russian',
      flag: <Flags.RU title="Russia" className="..."/>,
    },
  ];

  const [selectedLanguage, setLanguage] = useState();

  const handleLanguageChange = (value) => {
    setLocalization(value);
  };

  const setLocalization = useCallback(
    (lang) => {
      setLanguage(lang);
      i18n.changeLanguage(lang);
    },
    [i18n]
  );

  useEffect(() => {
    const lastLanguage = localStorage.getItem('i18nextLng')
      ? localStorage.getItem('i18nextLng')
      : 'en';
    setLocalization(lastLanguage);
  }, [setLocalization]);

  const executeFeature = () => featureRef.current.scrollIntoView();
  const executeTeam = () => teamRef.current.scrollIntoView();

  return (
    <div className="App">
      <Navigation 
        executeFeature={executeFeature}
        executeTeam={executeTeam}
        languages={languages}
        selectedLanguage={selectedLanguage}
        handleLanguageChange={handleLanguageChange} />
      <Route exact path="/" component={() => <LandingPage feature={featureRef} team={teamRef} />} />
      <Footer />
    </div>
  );
}

export default withRouter(withTranslation(['translation', 'common'])(App));
