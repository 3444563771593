import React from 'react';
import { Link } from 'react-router-dom';
import pc1 from '../../assets/images/Group 39311.png';
import pc2 from '../../assets/images/Group 39232.png';

import pcd1 from '../../assets/images/Rectangle.png';
import pcd2 from '../../assets/images/Rectangle 386.svg';
import pcd3 from '../../assets/images/Polygon 1.svg';
import pcd5 from '../../assets/images/GpadNew 1.png';
import pcd6 from '../../assets/images/GpadNew 2.png';
import pcd7 from '../../assets/images/Rectangle.png';
import pcd8 from '../../assets/images/Rectangle 386.svg';
import pcd9 from '../../assets/images/Polygon 1.svg';

import { withTranslation } from 'react-i18next';

const PhotoContent = ({t, handlePopup , executeInvestors, executeRoadmap}) => {
    return (
        <section className="photo-content">
            <div className="photo-content__wrapper">
                <div className="photo-content__item">
                    <div className="photo-content__content">
                        <h2 className="photo-content__title">{t('landing_page.photo_content.pc1.title')}</h2>
                        <p>{t('landing_page.photo_content.pc1.content')}</p>
                        {/* <Link className="btn--outlined coming-soon" to="#">Read our Manifesto</Link> */}
                        <button className="btn--outlined coming-soon" onClick={handlePopup}>{t('landing_page.photo_content.pc1.button')}</button>
                    </div>
                    <div className="photo-content__image">
                        <img src={pc1} alt={t('landing_page.photo_content.pc1.title')} />
                        
                        <img className="rectangle first spinning" src={pcd1} alt="" />
                        <img className="diamond first spinning" src={pcd2} alt="" />
                        <img className="triangle first spinning" src={pcd3}  alt=""/>
                    </div>
                    <div className="blur-circle grey bounce-grey"></div>
                    <div className="blur-circle purple bounce-purple"></div>
                    <div className="blur-circle green bounce-green"></div>
                </div>
                <div className="photo-content__item photo-content__item--reverse">
                    <div className="photo-content__content">
                        <h2 className="photo-content__title">{t('landing_page.photo_content.pc2.title')}</h2>
                        <p>{t('landing_page.photo_content.pc2.content')}</p>
                        <Link className="btn--outlined" to="#roadmap" onClick={executeRoadmap}>{t('landing_page.photo_content.pc2.button')}</Link>
                    </div>
                    <div className="photo-content__image">
                        <div className="photo-content__glitch">
                            <img src={pc2} alt={t('landing_page.photo_content.pc2.title')} />
                        </div>
                        <img src={pc2} alt={t('landing_page.photo_content.pc2.title')} />
                        <div className="blur-circle light-blue"></div>
                    </div>
                </div>
                <div className="photo-content__item">
                    <div className="photo-content__content">
                        <h2 className="photo-content__title">{t('landing_page.photo_content.pc3.title')}</h2>
                        <p>{t('landing_page.photo_content.pc3.content')}</p>
                        <Link className="btn--outlined" to="#investors" onClick={executeInvestors}>{t('landing_page.photo_content.pc3.button')}</Link>
                    </div>
                    <div className="photo-content__image photo-content__image--fix-height">
                        <img className="controller controller--front bobbing" src={pcd5} alt="" />
                        <img className="controller controller--back bobbing" src={pcd6} alt="" />
                        <img className="rectangle spinning" src={pcd7} alt="" />
                        <img className="diamond spinning" src={pcd8} alt="" />
                        <img className="triangle spinning" src={pcd9} alt="" />
                        {/* <img src={pc3} alt={t('landing_page.photo_content.pc3.title')} /> */}
                    </div>
                    <div className="blur-circle purple bounce-purple last-item"></div>
                    <div className="blur-circle green last-item bounce-green"></div>
                </div>
            </div>
        </section>
    );
}

export default withTranslation(['translation'])(PhotoContent);
